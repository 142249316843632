/**
 * @prettier
 */

import createBehavior from '@js/functions/createBehavior.js';
import ax from '@js/functions/axios.js';
import { isBreakpoint } from '@area17/a17-helpers';
import AdyenCheckout from '@adyen/adyen-web';

const COMPONENT_CONTAINER_SELECTOR = '#component-container';
const ADYEN_VERSION = '5.30.1'; // must follow adyen package.json @adyen/adyen-web
const ADYEN_CSS_INTEGRITY_HASH = 'sha384-+NMM0sr9h/+Ok6Ih79UYQwQKpGE2FCkfz9BuRa3s10p8/PTGKtjb+R8ycBHjZos/';
/* Integrity hash provided by adyen : https://docs.adyen.com/online-payments/release-notes?integration_type=web&tab=embed-scriptand-stylesheet_2021-10-05-b7ej_2 */

const QUICK_PAYMENTS = {
  paywithgoogle: {
    name: 'Google Pay',
  },
  applepay: {
    name: 'Apple Pay',
  },
  alipay: {
    name: 'Alipay',
  },
};

const adyenBehavior = createBehavior(
  'adyen',
  {
    bindUI() {
      this.ui = {};
      this.ui.submit = document.querySelectorAll('[data-adyen-submit]');
      this.ui.submitLabel = this.getChild('submit')?.innerText;
      this.ui.sidebar = document.querySelector(`#${this.sidebarId}`);
      this.ui.paymentBlock = this.getChild('payment-block');
      this.ui.inputs = this.node.querySelectorAll('input');
      this.ui.formLabels = this.getChildren('formlabel');
      this.ui.quickBtns = this.getChild('quick-btns');
      this.ui.quickBtnMsg = this.getChild('quick-btn-msg');
      this.ui.inputEmail = this.node.querySelector('#email');
    },
    bindEvents() {
      this.node.addEventListener('submit', this.handleFormSubmit);
      Array.from(this.ui.submit).forEach((btn) => {
        if (btn.getAttribute('type') === 'button') {
          btn.addEventListener('click', this.handleFormSubmit);
        }
      });

      // Add input event on inputs
      if (this.ui.inputs.length) {
        Array.from(this.ui.inputs).map((input) => {
          input.addEventListener('input', this.handleInputChange, false);
        });
      }

      document.addEventListener(
        'adyen:paymentStart',
        this.handlePaymentStart,
        false,
      );
    },
    unbindEvents() {
      document.removeEventListener(
        'adyen:paymentStart',
        this.handlePaymentStart,
      );

      this.node.removeEventListener('submit', this.handleFormSubmit);
      Array.from(this.ui.submit).forEach((btn) => {
        if (btn.getAttribute('type') === 'button') {
          btn.removeEventListener('click', this.handleFormSubmit);
        }
      });
    },
    /**
     * Caches the input value and corresponding validity whenever its value changes
     *
     * @param {document#event:change} change event
     */
    handleInputChange(e) {
      let input = this.form[e.target.id];
      if (e.target.type === 'checkbox') {
        return;
      }
      // Clear custom validation error
      e.target.setCustomValidity('');
      input.value = e.target.value;
      input.isValid = e.target.value && e.target.value !== '' ? true : false;

      this.updateNameField();
      this.updateUI();
    },
    updateNameField() {
      if(this.form['first_name'].value && this.form['last_name'].value) {
        this.form['name'].value = this.form['first_name'].value + ' ' + this.form['last_name'].value;
        this.form['name'].isValid = true;
      } else {
        this.form['name'].isValid = false;
      }
    },
    /**
     * Resolve whether the form is valid,
     * based on its inputs and cart total
     */
    isFormValid() {
      for (const index in this.form) {
        if (index === 'card' && (this.isCartFree() || this.getQuickPayment())) {
          // If the user doesn't need to fill in the card details
          // (either a free cart, of they've used a quick payment), we can ignore any invalid card field
        } else {

          // Hack to force membership email
          if ( index === 'email' && this.ui.inputEmail.value ) {
            this.form[index] = {
              value: this.ui.inputEmail.value,
              isValid: true
            }
          }

          if (!this.form[index] || !this.form[index].isValid) {
            return false;
          }
        }
      }
      return true;
    },
    /**
     * When the user submits the payment form, call the transaction
     *
     * @param {document#event:submit} Form submit event
     */
    handleFormSubmit(evt) {
      if (!this.node.checkValidity()) {
        this.node.reportValidity();
        evt.preventDefault();
      } else if (this.node.checkValidity()) {
        evt.preventDefault();
        this.APITransaction();
      }
    },
    /**
     * Handles the payment response, either from APITransaction or
     * from a server hydration after a 3DS Redirect
     *
     * @param {Object} payment_response
     * @param {String} redirect_url
     */
    handlePaymentResponse(payment_response, redirect_url) {
      if (payment_response?.action) {
        if (payment_response.action.type === 'threeDS2') {
          Array.from(this.ui.submit).forEach((submit) => {
            submit.setAttribute('disabled', '');
          });
        }

        this.checkout
          .createFromAction(payment_response.action)
          .mount(COMPONENT_CONTAINER_SELECTOR);
        document.dispatchEvent(new CustomEvent('adyen:relayout'));
      } else {
        if (payment_response?.resultCode) {
          switch (payment_response.resultCode) {
            case 'Authorised': {
              if (redirect_url) {
                if (this.checkoutCreditCardUI) {
                  this.paymentMethodData = null;
                  this.checkoutCreditCardUI.unmount();
                  this.checkoutCreditCardUI = null;
                }
                document.dispatchEvent(
                  new CustomEvent('adyen:confirmOrder', {
                    detail: {
                      redirect_url,
                    },
                  }),
                );
              } else {
                console.error('order authorized but no redirect_url provided.');
              }
              break;
            }
            case 'Refused':
            case 'Cancelled':
            case 'Error': {
              this.createPaymentForm();
              this.updateUI();

              document.dispatchEvent(
                new CustomEvent('adyen:error', {
                  detail: {
                    error:
                      payment_response.message || payment_response.resultCode,
                  },
                }),
              );
              break;
            }
            default: {
              console.error(
                'Unhandled resultCode',
                payment_response.resultCode,
              );
            }
          }
        } else {
          if (redirect_url) {
            document.dispatchEvent(
              new CustomEvent('adyen:confirmOrder', {
                detail: {
                  redirect_url,
                },
              }),
            );
          } else {
            console.error('order authorized but no redirect_url provided.');
          }
        }
      }
    },
    /**
     * Calls the /transaction endpoint to process the payment
     *
     * @param {{ paymentDetails: object, paymentData: object }} Optional 3DS-specific data, likely from handleAdyenOnAdditionalDetails
     */
    APITransaction({ paymentDetails = null, paymentData = null } = {}) {
      var optinField = document.getElementById('ioptin');
      // var firstnameField = document.querySelector('input[name="firstname"]');
      // var lastnameField = document.querySelector('input[name="lastname"]');
      var birthdayField = document.querySelector('input[name="birthday"]');
      var addressField = document.querySelector('input[name="address"]');
      var cityField = document.querySelector('input[name="city"]');
      var zipcodeField = document.querySelector('input[name="zipcode"]');
      var phoneField = document.querySelector('input[name="phone"]');

      var isGift = document.querySelector('input[name="gift_pass"]');
      var firstnameFieldHolder = document.querySelector('input[name="holder_firstname"]');
      var lastnameFieldHolder = document.querySelector('input[name="holder_lastname"]');
      var emailFieldHolder = document.querySelector('input[name="holder_email"]');
      var birthdayFieldHolder = document.querySelector('input[name="holder_birthday"]');
      var addressFieldHolder = document.querySelector('input[name="holder_address"]');
      var cityFieldHolder = document.querySelector('input[name="holder_city"]');
      var zipcodeFieldHolder = document.querySelector('input[name="holder_zipcode"]');
      var phoneFieldHolder = document.querySelector('input[name="holder_phone"]');

      var firstnameFieldBuyer = document.querySelector('input[name="buyer_firstname"]');
      var lastnameFieldBuyer = document.querySelector('input[name="buyer_lastname"]');
      var emailFieldBuyer = document.querySelector('input[name="buyer_email"]');
      var birthdayFieldBuyer = document.querySelector('input[name="buyer_birthday"]');
      var addressFieldBuyer = document.querySelector('input[name="buyer_address"]');
      var cityFieldBuyer = document.querySelector('input[name="buyer_city"]');
      var zipcodeFieldBuyer = document.querySelector('input[name="buyer_zipcode"]');
      var phoneFieldBuyer = document.querySelector('input[name="buyer_phone"]');

      let customer = {
        name: this.form['name'].value,
        email: this.form['email'].value,
        firstname: this.form['first_name'].value,
        lastname: this.form['last_name'].value,
        birthday: birthdayField ? birthdayField.value : '',
        address: addressField ? addressField.value : '',
        city: cityField ? cityField.value : '',
        zipcode: zipcodeField ? zipcodeField.value : '',
        phone: phoneField ? phoneField.value : '',
        optin: optinField.checked,
      };

      let holder = {
        email: emailFieldHolder ? emailFieldHolder.value : '',
        firstname: firstnameFieldHolder ? firstnameFieldHolder.value : '',
        lastname: lastnameFieldHolder ? lastnameFieldHolder.value : '',
        birthday: birthdayFieldHolder ? birthdayFieldHolder.value : '',
        address: addressFieldHolder ? addressFieldHolder.value : '',
        city: cityFieldHolder ? cityFieldHolder.value : '',
        zipcode: zipcodeFieldHolder ? zipcodeFieldHolder.value : '',
        phone: phoneFieldHolder ? phoneFieldHolder.value : '',
      };

      const hasBuyer = !!(emailFieldBuyer && emailFieldBuyer.value);

      let buyer = {
        email: hasBuyer && emailFieldBuyer ? emailFieldBuyer.value : (!hasBuyer ? customer.email : null),
        firstname: hasBuyer && firstnameFieldBuyer ? firstnameFieldBuyer.value : (!hasBuyer ? customer.firstname : null),
        lastname: hasBuyer && lastnameFieldBuyer ? lastnameFieldBuyer.value : (!hasBuyer ? customer.lastname : null),
        birthday: hasBuyer && birthdayFieldBuyer ? birthdayFieldBuyer.value : (!hasBuyer ? customer.birthday : null),
        address: hasBuyer && addressFieldBuyer ? addressFieldBuyer.value : (!hasBuyer ? customer.address : null),
        city: hasBuyer && cityFieldBuyer ? cityFieldBuyer.value : (!hasBuyer ? customer.city : null),
        zipcode: hasBuyer && zipcodeFieldBuyer ? zipcodeFieldBuyer.value : (!hasBuyer ? customer.zipcode : null),
        phone: hasBuyer && phoneFieldBuyer ? phoneFieldBuyer.value : (!hasBuyer ? customer.phone : null),
        optin: customer.optin, /// FIXME: what should we do with this field? It's double optin from two different forms
      };

      if (!customer.name || !customer.email) {
        return false;
      }

      let data = {
        transactionId: this.transactionInfos.transactionId,
        totalAmountCents: this.transactionInfos.totalAmountCents,
        customer, // credit card holder / payer
        holder: isGift && isGift.checked ? holder : null, // pass holder
        buyer, // person that actually bought the pass
        paymentMethod:
          this.paymentMethodData && !this.isCartFree()
            ? this.paymentMethodData
            : undefined,
        payment_details: paymentDetails || undefined,
        payment_data: paymentData || undefined,
      };

      this.ui.sidebar.classList.add(this.sidebarLoadingKlass);

      let localeParam = `?locale=${
        this.configuration['locale'] ? this.configuration['locale'] : 'en'
      }`;

      let url = `/api/v1/online-sales/carts/${this.cart.cartId}/transaction${localeParam}`;

      document.dispatchEvent(
        new CustomEvent('adyen:transactionStarted', {
          detail: {},
        }),
      );

      this.waitForSession(() => {
        ax({
          method: 'POST',
          url,
          data,
        })
          .then((res) => {
            this.ui.sidebar.classList.remove(this.sidebarLoadingKlass);
            let { payment_response, redirect_url } = res.data.response;
            this.handlePaymentResponse(payment_response, redirect_url);
          })
          .catch((error) => {
            this.ui.sidebar.classList.remove(this.sidebarLoadingKlass);
            const errorData = error.response.data;
            // this.createPaymentForm();
            // this.updateUI();
            if (errorData && errorData.response && errorData.response.errors) {
              if (errorData.response.errors['customer.email']) {
                let emailField = this.node.querySelector('[type="email"]')
                if (emailField) {
                  emailField.setCustomValidity('The email field is invalid');
                  this.node.reportValidity();
                  return
                }
              }
            }
            console.error('Transaction error', error);
            document.dispatchEvent(
              new CustomEvent('transaction:error', {
                detail: {
                  error,
                },
              }),
            );
          });
      });
    },
    /**
     * Handle the onChange event from Adyen SDK
     *
     * @param {Object} state - encrypted payment values from Adyen
     */
    handleAdyenOnChange(state) {
      // Store the encrypted payment values for in /transaction call
      this.paymentMethodData = state.data;

      // Update card input validity, based on latest from Adyen
      this.form['card'] = {
        isValid: state.isValid,
      };

      this.updateUI();
    },
    handleAdyenOnAdditionalDetails(state) {
      this.APITransaction({
        paymentDetails: state.data.details,
        paymentData: state.data.paymentData,
      });
    },
    loadAdyenStyles() {

      let link = document.createElement('link');
      if(this.configuration.environment === 'test'){
        link.href = `https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/${ADYEN_VERSION}/adyen.css`;
      }else{
        link.href = `https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/${ADYEN_VERSION}/adyen.css`;
      }

      link.rel = 'stylesheet';
      link.integrity = ADYEN_CSS_INTEGRITY_HASH;
      link.crossOrigin = 'anonymous';

      document.head.appendChild(link);
    },
    loadGooglePayScript() {
      return new Promise((resolve) => {
        let script = document.createElement('script');
        script.onload = resolve;
        script.src = 'https://pay.google.com/gp/p/js/pay.js';
        document.head.appendChild(script);
      });
    },
    async createPaymentForm() {
      if (!this.configuration.paymentMethodsResponse) {
        return;
      }
      this.checkout = await AdyenCheckout(this.configuration);

      // Once ready : Add Payment methods CC and ApplePay
      this.mountCreditCardComponent();
      this.mountApplePayComponent();
      // this.mountGooglePayComponent();
      // this.mountAlipayComponent();
      this.updateUI();
    },
    mountCreditCardComponent() {
      this.checkoutCreditCardUI = this.checkout
        .create('card', {
          styles: {
            base: {
              fontFamily: 'serif',
              fontSmoothing: 'antialiased',
              webkitFontSmoothing: 'antialiased',
              fontSize: isBreakpoint('small') ? '18px' : '20px',
            },
            placeholder: {
              color: '#B0B3AF',
            },
          },
        })
        .mount(COMPONENT_CONTAINER_SELECTOR);
    },
    mountApplePayComponent() {
      const applePayConfiguration = {
        amount: {
          value: this.transactionInfos.totalAmountCents,
          currency: this.configuration.currency_code || 'EUR',
        },
        countryCode: this.configuration.country_code || 'FR',
        merchantCapabilities: [
          'supports3DS',
          'supportsCredit',
          'supportsDebit',
        ],
        supportedNetworks: ['amex', 'masterCard', 'visa'],
        requiredShippingContactFields: ['name', 'email'],
        totalPriceLabel: this.configuration.apple?.merchant?.name,
        configuration: {
          merchantName: this.configuration.apple?.merchant?.name,
          merchantIdentifier: this.configuration.apple?.merchant?.identifier,
        },
        buttonColor: 'white-with-line',
        onValidateMerchant: (resolve, reject, validationURL) => {
          ax({
            method: 'POST',
            url: '/api/v1/online-sales/apple-pay/validate-merchant',
            data: {
              validationURL,
            },
          })
            .then((res) => {
              resolve(res.data.response);
            })
            .catch((err) => {
              console.error('err', err);
              document.dispatchEvent(
                new CustomEvent('adyen:error', {
                  detail: {
                    error: 'Not validated',
                  },
                }),
              );
              reject(err);
            });
        },
        onAuthorized: (resolve, reject, evt) => {
          let { emailAddress = null, givenName = null, familyName = null } =
            evt?.payment?.shippingContact || {};

          this.populateDetailsFromQuickPayment({
            first_name: givenName ? givenName : '',
            last_name: familyName ? familyName : '',
            email: emailAddress,
          });

          if (this.isFormValid()) {
            this.APITransaction();
            resolve();
          } else {
            this.paymentMethodData = null;
            reject();
          }

          this.updateUI();
        },
        onSubmit: (state) => {
          this.paymentMethodData = state.data;
          this.updateUI();

          if (this.isFormValid()) {
            this.APITransaction();
          }
        },
      };
      let applepay = this.checkout.create('applepay', applePayConfiguration);
      applepay
        .isAvailable()
        .then(() => {
          applepay.mount('#applepay-container');
          document.dispatchEvent(new CustomEvent('adyen:relayout'));
          document
            .getElementById('applepay-container')
            .classList.add('is-enabled');
        })
        .catch((e) => {});
    },
    mountGooglePayComponent() {
      const googlepay = this.checkout.create('paywithgoogle', {
        environment:
          this.configuration.environment === 'test' ? 'TEST' : 'PRODUCTION',
        amount: {
          currency: 'EUR',
          value: this.transactionInfos.totalAmountCents,
        },
        configuration: {
          gatewayMerchantId: this.configuration.google?.merchant.gateway_id,
          merchantIdentifier: this.configuration.google?.merchant.identifier,
          merchantName: this.configuration.google?.merchant.name,
        },
        emailRequired: true,
        billingAddressRequired: true,
        billingAddressParameters: {
          format: 'MIN',
        },
        buttonColor: 'white',
        onAuthorized: (data, ...args) => {
          this.populateDetailsFromQuickPayment({
            first_name: ' ',
            last_name: data.paymentMethodData?.info?.billingAddress?.name,
            email: data.email,
          });

          this.updateUI();

          if (this.isFormValid() && this.paymentMethodData) {
            this.APITransaction();
          }
        },
        onSubmit: (state) => {
          this.paymentMethodData = state.data;
          this.updateUI();

          if (this.isFormValid() && this.paymentMethodData) {
            this.APITransaction();
          }
        },
      });

      googlepay
        .isAvailable()
        .then(() => {
          googlepay.mount('#googlepay-container');
          document.dispatchEvent(new CustomEvent('adyen:relayout'));
          document
            .getElementById('googlepay-container')
            .classList.add('is-enabled');
        })
        .catch((e) => {});
    },
    mountAlipayComponent() {
      try {
        this.checkout
          .create('alipay', {
            onSubmit: (state) => {
              this.paymentMethodData = state.data;
              this.updateUI();

              if (this.isFormValid()) {
                // The user has already filled out their name & email
                // so we're good to go!
                this.APITransaction();
              }
            },
          })
          .mount('#alipay-container');
        document.dispatchEvent(new CustomEvent('adyen:relayout'));
        document.getElementById('alipay-container').classList.add('is-enabled');
      } catch (err) {
        console.error('Alipay not available.');
      }
    },
    handlePaymentStart(e) {
      let { paymentMethodsResponse = null, cart, transactionInfos } = e.detail;
      this.cart = cart;

      this.configuration.paymentMethodsResponse = paymentMethodsResponse;
      this.transactionInfos = transactionInfos;

      this.createPaymentForm();
      this.updateUI();
    },
    handleRemovePaymentMethodClick(evt) {
      evt.preventDefault();
      this.paymentMethodData = null;
      this.createPaymentForm();
      this.updateUI();
    },
    /**
     * Certain quick payment solutions (e.g. Google Pay)
     * can provide the name & email from their service.
     * This will populate our form fields with that data (if it isn't already filled in manually)
     */
    populateDetailsFromQuickPayment({ first_name, last_name, email }) {
      if (first_name) {
        this.form['first_name'].value = first_name;
        this.form['first_name'].isValid = true; // Assume this data is valid from quick payment
        this.node.querySelector('#first_name').value = this.form['first_name'].value;
      }
      if (last_name) {
        this.form['last_name'].value = last_name;
        this.form['last_name'].isValid = true; // Assume this data is valid from quick payment
        this.node.querySelector('#last_name').value = this.form['last_name'].value;
      }
      if (first_name || last_name) {
        this.form['name'].value = (first_name ? first_name + ' ' : '') + (last_name ? last_name : '');
        this.form['name'].isValid = true; // Assume this data is valid from quick payment
        this.node.querySelector('#name').value = this.form['name'].value;
      }
      if (email) {
        this.form['email'].value = email;
        this.form['email'].isValid = true; // Assume this data is valid from quick payment
        this.node.querySelector('#email').value = this.form['email'].value;
      }
    },
    isCartFree() {
      // Wait for this.cart to populate before safely considering it 'free'
      if (!this.cart) {
        return false;
      }

      // Sum the total cart amount from each product's prices
      let totalCartAmount = 0;
      this.cart.products.forEach((product) => {
        product.prices.forEach((price) => {
          totalCartAmount += price.price * price.quantity;
        });
      });

      return totalCartAmount === 0;
    },
    getQuickPayment() {
      let payment = QUICK_PAYMENTS[this.paymentMethodData?.paymentMethod?.type];
      if (payment != null) {
        return payment;
      } else {
        return false;
      }
    },
    updateUI() {
      let isCartFree = this.isCartFree();
      let quickPayment = this.getQuickPayment();

      // Update input labels if it's free cart
      Array.from(this.ui.formLabels).forEach((label) => {
        if (label.dataset.adyenFormlabel) {
          let labels = JSON.parse(label.dataset.adyenFormlabel);
          if (labels.free) {
            label.innerHTML = isCartFree ? labels.free : labels.default;
          }
        }
      });

      // Update submit button text if it's a free cart
      for (let submit of Array.from(this.ui.submit)) {
        if (submit.dataset.adyenSubmit) {
          let labels = JSON.parse(submit.dataset.adyenSubmit);
          if (labels.free) {
            submit.innerHTML = isCartFree ? labels.free : labels.default;
          }
        }
      }

      let hidePaymentUI = quickPayment || isCartFree;
      this.ui.paymentBlock.classList.toggle('is-hidden', hidePaymentUI);
      this.ui.quickBtns.classList.toggle('is-disabled', hidePaymentUI);

      if (quickPayment) {
        this.ui.quickBtnMsg.innerHTML = `${this.configuration.quick_btns_msg_template.replace(
          '{{name}}',
          quickPayment.name,
        )}<br><a href="#" class="link">${
          this.configuration.quick_btns_remove_label_template
        }</a>`;
        this.ui.quickBtnMsg
          .querySelector('a')
          .addEventListener('click', this.handleRemovePaymentMethodClick);
        this.node.querySelector('#name').focus();
      } else {
        this.ui.quickBtnMsg.innerText = '';
      }

      let isFormValid = this.isFormValid();

      if (isFormValid) {
        Array.from(this.ui.submit).forEach((submit) => {
          submit.removeAttribute('disabled');
        });
      } else {
        Array.from(this.ui.submit).forEach((submit) => {
          submit.setAttribute('disabled', '');
        });
      }

      document.dispatchEvent(new CustomEvent('adyen:relayout'));
    },
    waitForSession(cb) {
      cb();
    },
  },
  {
    async init() {
      this.sidebarId = 'sidebar-cart';
      this.sidebarLoadingKlass = 'is-loading';
      this.configuration = {
        ...JSON.parse(this.options.config),
        onChange: this.handleAdyenOnChange,
        onAdditionalDetails: this.handleAdyenOnAdditionalDetails,
        translations: {
          'en-US': {
            'creditCard.numberField.title': '',
            'creditCard.cvcField.placeholder': 'CVC',
          },
          'fr-FR': {
            'creditCard.numberField.title': '',
            'creditCard.cvcField.placeholder': 'CVC',
          },
        },
      };

      this.loadAdyenStyles();
      // await this.loadGooglePayScript();

      // This will store a reference to the cart object, such as the ID and the products within it
      this.cart = null;

      // This will store the data for the chosen payment method
      // whether it's a credit/debit card, or a quick payment
      this.paymentMethodData = null;

      this.form = {
        card: { isValid: false },
        name: { isValid: false, value: '' },
        first_name: { isValid: false, value: '' },
        last_name: { isValid: false, value: '' },
        email: { isValid: false, value: '' },
      };

      this.bindUI();
      this.bindEvents();

      // Hydrate the `this.form` cache with any pre-filled values
      Array.from(this.ui.inputs).map((input) => {
        if (input.id in this.form) {
          this.form[input.id].value = input.value;
        }
      });

      this.updateUI();

      // We already have payment details, so open the sidebar on step 3
      // (once the sidebar behavior's initial events listeners are added)
      setTimeout(() => {
        if (this.configuration.payment_error) {
          // We don't want this to trigger again if the cart remounts
          // during the same page load
          let paymentError = { ...this.configuration.payment_error };
          this.configuration.payment_error = null;

          // Open step 3 again, so they can re-do the payment...
          this.ui.sidebar.dispatchEvent(
            new CustomEvent('cart-sidebar:open', {
              detail: {
                step: 3,
              },
            }),
          );

          // Only trigger this payment response once
          // adyen cart amount has been checked, etc.
          let handleAdyenPaymentStart = () => {
            // Only run this listener once
            document.removeEventListener(
              'adyen:paymentStart',
              handleAdyenPaymentStart,
            );

            // Handle the payment error as a payment response
            // (Even though the resultCode won't be "Authorized")
            this.handlePaymentResponse(paymentError.payment_response);
          };
          document.addEventListener(
            'adyen:paymentStart',
            handleAdyenPaymentStart,
          );
        }
      }, 0);
    },
    destroy() {
      this.unbindEvents();
    },
  },
);

export default adyenBehavior;
