import createBehavior from '@js/functions/createBehavior.js';
import loadScript from '@js/functions/load-script.js';

const chatbotBehavior = createBehavior(
  'chatbot',
  {
    hideChatbot() {
      console.log('🤖 Hiding chatbot, triggered by:', new Error().stack);
      setTimeout(() => {
        const chatbotDiv = document.querySelector('div[style*="z-index: 2147483647"]');
        if (chatbotDiv) {
          chatbotDiv.style.display = 'none';
        }
      }, 1000);
    },

    unhideChatbot() {
      console.log('🤖 Showing chatbot, triggered by:', new Error().stack);
      const chatbotDiv = document.querySelector('div[style*="z-index: 2147483647"]');
      if (chatbotDiv) {
        chatbotDiv.style.display = 'block';
      }
    },

    isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },

    handleMobileVisibility() {
      const isMobile = this.isMobileDevice();
      console.log('Mobile visibility check:', {
        isMobile,
        userAgent: navigator.userAgent
      });

      if (isMobile) {
        this.unhideChatbot();
      }
    },

    loadChatSDK() {
      const chatbotContainer = document.querySelector('[data-chatbot-container]');
      if (!chatbotContainer) return;

      return loadScript('https://webchat.askmonastudio.com/scripts/embed.mjs').then((data) => {
        const askmonaInstance = Askmona.start({
          wat: this.options['app-id'],
          appMode: "fab",
          path: "/chat/info",
          disabledNavigation: true,
        });
        
        this.askmonaInstance = askmonaInstance;
        this.observeIframeCreation();
        return data;
      });
    },

    observeIframeCreation() {
      const observer = new MutationObserver((mutations) => {
        if (document.querySelector('iframe[src*="webchat.askmonastudio.com"]')) {
          this.handleMobileVisibility();
          observer.disconnect();
        }
      });
      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
    },
  },
  {
    init() {
      console.log('🤖 Initializing chatbot behavior');
      this.hideChatbot = this.hideChatbot.bind(this);
      this.unhideChatbot = this.unhideChatbot.bind(this);
      this.handleMobileVisibility = this.handleMobileVisibility.bind(this);
      this.observeIframeCreation = this.observeIframeCreation.bind(this);
      
      const chatbotContainer = document.querySelector('[data-chatbot-container]');
      if (!chatbotContainer) {
        console.log('🤖 No chatbot container found');
        return;
      }

      // Debug listener to catch all sidebar events
      document.addEventListener('sidebar:open', (e) => {
        console.log('🤖 Sidebar open event caught:', e.detail);
      });
      document.addEventListener('sidebar:close', (e) => {
        console.log('🤖 Sidebar close event caught:', e.detail);
      });
      document.addEventListener('sidebar:closeFromOutside', (e) => {
        console.log('🤖 Sidebar closeFromOutside event caught:', e.detail);
      });

      const iframe = document.querySelector('iframe[src*="webchat.askmonastudio.com"]');

      if (!iframe && !this.askmonaInstance) {
        this.loadChatSDK();
      } else if(iframe) {
        this.unhideChatbot();
      }

      console.log('🤖 Adding event listeners');
      window.addEventListener('resize', this.handleMobileVisibility);
      document.addEventListener('nav:opened', this.hideChatbot);
      document.addEventListener('nav:closed', this.unhideChatbot);
      document.addEventListener('sidebar:open', this.hideChatbot);
      document.addEventListener('sidebar:close', this.unhideChatbot);
      document.addEventListener('sidebar:closeFromOutside', this.unhideChatbot);
      console.log('🤖 Event listeners added');
    },

    destroy() {
      // Remove the chatbot container if it exists
      const chatbotDiv = document.querySelector('div[style*="z-index: 2147483647"]');
      if (chatbotDiv) {
        chatbotDiv.remove();
      }
      
      window.removeEventListener('resize', this.handleMobileVisibility);
      document.removeEventListener('nav:opened', this.hideChatbot);
      document.removeEventListener('nav:closed', this.unhideChatbot);
      document.removeEventListener('sidebar:open', this.hideChatbot);
      document.removeEventListener('sidebar:close', this.unhideChatbot);
      document.removeEventListener('sidebar:closeFromOutside', this.unhideChatbot);
    },
  }
);

export default chatbotBehavior;
